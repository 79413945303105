@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;500;800&display=swap');

body {
  margin: 0;
  font-family: Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  color: #001529 !important;
}

.ant-btn {
  box-shadow: 0 0px 0 !important;
}

.ant-btn-primary:hover {
  background-color: #002f5c !important;
}
