.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-color: rgba(224, 224, 224, 0.85);
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  flex-direction: column;
}

.login-container {
  height: 400px;
  width: 400px;
  position: relative;
}

.login-back {
  //min-height: 100%;
  //min-width: 1024px;
  height: 100vh;
  width: 100%;
  //height: auto;
  //position: fixed;
  //top: 0;
  background-size: cover;
  background-position: center center;
  //left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {
  width: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
}